.App {
  text-align: center;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
}

.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  padding: 10px 0;
  z-index: 1000; /* Ensure the header is on top */
  display: flex;
  justify-content: space-between; /* Adjusted to spread out left and right sections */
  align-items: center;
}

.header-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom */
}

.header-left img {
  width: 200px; /* Original size */
  height: auto;
  margin-top: 10px; /* Adjust to lower the image */
}

.header-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right img {
  width: 50px; /* Increase the size */
  height: auto;
  cursor: pointer;
}

.nav-tabs {
  list-style-type: none;
  padding: 0;
  margin: 0 20px; /* Added margin to create space between images and tabs */
  display: flex;
  justify-content: center;
  flex-grow: 2;
  z-index: 1001; /* Ensure tabs are visible */
}

.nav-tabs li {
  margin: 0 15px;
}

.nav-tabs a {
  color: white;  /* Default color for inactive tabs */
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s, border-bottom 0.3s;
}

.nav-tabs a.active-tab {
  color: #6535F3;  /* Purple color for the active tab */
  border-bottom: 2px solid #6535F3;  /* Underline for the active tab */
}



.section {
  position: relative; /* Ensure content stays above the video */
  height: 100vh; /* Each section fills the viewport */
  padding-top: 80px; /* Adjusted for the larger header */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure content stays above the video */
}

.home-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire viewport */
  z-index: 1; /* Ensure the video is behind the content */
}


.intertwined-images {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image {
  position: absolute;
  top: -200px; /* Keep the same position */
  right: -520px; /* Keep the same position */
  z-index: 1;
  width: 1000px; /* Keep the same size */
  height: auto;
  opacity: 0.9;
}

.eventcard-image {
  position: absolute;
  bottom: -160px; /* Keep the same position */
  left: 280px; /* Keep the same position */
  z-index: 2;
  width: 1000px; /* Keep the same size */
  height: auto;
  opacity: 0.9;
}

.centered-text {
  position: relative;
  z-index: 3; /* Ensure the text appears above the images */
  color: white; /* Ensure the text is visible over the images */
  font-family: Arial, sans-serif; /* Adjust font-family as needed */
  padding: 20px;
  top: -100px; /* Move the text upward by 140px; adjust as needed */
  text-align: left; /* Align text to the left */
  max-width: 800px; /* Restrict the width to avoid overflow */
  margin: 0 auto; /* Center the container */
  transform: translateX(-100px); /* Move the text left by 50px */
}

.centered-text h1 {
  font-size: 2.5rem; /* Smaller header size */
  margin-bottom: 20px; /* Space between header and paragraph */
}

.centered-text p {
  font-size: 2rem; /* Larger text size */
  line-height: 1.5;
  margin: 0; /* Ensure no extra margin is added */
  white-space: pre-line; /* Preserve line breaks */
}


.store-links {
  margin-top: 20px; /* Add some space above the store badges */
  display: flex;
  justify-content: flex-start; /* Align badges to the left */
  gap: 20px; /* Space between the badges */
}

.store-badge {
  width: 150px; /* Adjust the size as needed */
  height: auto;
}

/* About Section Styles */
.about-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Fallback color */
}

/* Background Video Styles */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers the entire area */
  z-index: 1; /* Places video behind content */
}

/* Overlay Styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
  z-index: 2; /* Places overlay above video but below content */
}

/* Content Container Styles */
.about-content {
  position: relative;
  z-index: 3; /* Places content above overlay and video */
  max-width: 800px; /* Sets maximum width for content */
  width: 90%; /* Responsive width */
  margin: 0 auto; /* Centers the content horizontally */
  padding: 40px;
  color: #ffffff;
  text-align: left; /* Left-aligns all text */
  border-radius: 10px; /* Rounds the corners */
  backdrop-filter: blur(5px); /* Adds blur effect to background */
}

/* Heading Styles */
.about-content h1 {
  text-align: center; /* Center-align the "HAKKIMIZDA" heading */
  font-size: 2rem; /* Adjust the size as needed */
  margin-bottom: 20px;
  font-weight: 700;
}

.about-content h2 {
  margin-bottom: 20px;
  font-weight: 700;
  text-align: left; /* Keep other headings left-aligned */
}

/* Paragraph Styles */
.about-content p {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 1.1rem;
}

/* List Styles */
.about-content ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.about-content ul li {
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 1.1rem;
}

/* Highlighted Text Styles */
.highlight {
  color: #6535F3; /* Purple color for "Eventle" */
  font-weight: 600;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .about-content {
    padding: 30px 20px;
  }

  .about-content h1,
  .about-content h2 {
    font-size: 1.5rem;
  }

  .about-content p,
  .about-content ul li {
    font-size: 1rem;
  }
}


.how-work-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
}

.how-work-content {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns: one for text, one for images */
  gap: 20px; /* Space between text and images */
  align-items: center; /* Center align the content vertically */
  width: 100%;
  max-width: 1200px;
  position: relative;
  transform: translateX(300px); /* Move the entire grid content to the right */
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end; /* Align text to the right side of the grid */
  text-align: left; /* Ensure text is left-aligned */
}

.text-column p {
  font-size: 1.4rem;
  line-height: 1.5;
  position: relative;
}

.text-column p:nth-child(1) {
  margin-bottom: 120px; /* Space below the first text block */
}

.text-column p:nth-child(2) {
  margin-bottom: 180px; /* Space below the second text block */
}

.text-column p:nth-child(3) {
  margin-bottom: 240px; /* Space below the third text block */
}

.text-column p:nth-child(4) {
  margin-bottom: 0; /* No space below the last text block */
}

.image-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align images to the left side of the grid */
}

.image-column img {
  width: 900px; /* Adjusted size to match typical usage */
  height: auto;
  position: relative;
  left: -100px; /* Move images slightly to the left */
  margin-bottom: -150px; /* Space between images */
}

.image-column img:last-of-type {
  margin-bottom: 0; /* Ensure last image stays within the container */
}

.header {
  color: #6535F3; /* Your specified color */
  font-weight: bold;
  font-size: 1.6rem;
  display: block;
  margin-bottom: 10px;
}

.section {
  text-align: center;
  padding: 20px;
}

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh; /* Full viewport height */
  text-align: center;
  padding: 20px;
  position: relative;
}

.contact-form {
  position: absolute;
  right: 20%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-form h2 {
  color: white;
  margin-bottom: 10px;
}

.contact-form p {
  color: white;
  margin-bottom: 20px;
}

.contact-input, .contact-textarea {
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid white;
  background: transparent;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-textarea {
  height: 100px;
  resize: none;
}

.submit-button {
  padding: 10px 20px;
  border: 2px solid white;
  background: transparent;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: white;
  color: black;
}


h1 {
  font-size: 3rem; /* Large font size for the header */
  margin-bottom: 10px; /* Closer space between the header and the store badges */
  color: #6535F3; /* Your specified header color */
}

.store-links-contact {
  display: flex;
  justify-content: center;
  gap: 20px; /* Reduced space between the store badges */
  margin-bottom: 350px; /* Add space below the store badges */
}

.store-badge-contact {
  width: 180px; /* Slightly smaller size for the badges */
  height: auto;
}

.contact-info {
  position: relative; /* Ensure it stays in the flow of the document */
  transform: translate(-200px, -300px); /* Move left and up */
  text-align: left; /* Align text to the left */
}

.contact-info h2 {
  font-size: 2.4rem; /* Slightly smaller header size */
  color: #6535F3; /* Header color */
  margin-bottom: 15px; /* Slightly reduced space below the header */
}

.contact-info p {
  margin: 3px 0; /* Reduced space between the email addresses */
  font-size: 1.4rem; /* Slightly smaller text size */
}
.contact-info a {
  color: white; /* Set the email link color to white */
  text-decoration: none; /* Remove the underline from the link */
}

.contact-info a:hover {
  text-decoration: underline; /* Optionally, add an underline on hover */
}

.social-icons {
  margin-top: 15px; /* Slightly reduced space above the icons */
}

.social-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}



.footer {
  width: 100%;
  text-align: center;
  color: #888; /* A subtle grey color for the footer text */
  font-size: 1rem;
  padding: 10px 0; /* Add some padding to the footer */
}
